import React, { useState, useEffect } from "react";
import { Modal, Dropdown, ButtonGroup } from "react-bootstrap";
import axios from "axios";
import "./BikeDetailsModal.css";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import mainlogo from "../../assets/full-size-logo.png";
import { useNavigate } from "react-router-dom";
import BikeImage from "./BikeImage";

export default function BikeDetailsModal({
  show,
  onHide,
  bike,
  duration,
  branch,
  pickupDate,
  dropDate,
}) {
  const [modelId, setModelId] = useState(null);
  const [detailedBikeInfo, setDetailedBikeInfo] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isBooking, setIsBooking] = useState(false); // State for "Book Now" button click
  const [showXMark, setShowXMark] = useState(false); // State for X mark button visibility
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginToBookBike, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryNumber, setSecondaryNumber] = useState("");
  const navigate = useNavigate();
  const handleCloseLoginToBookBike = () => setShow(false);
  const handleShowLoginToBookBike = () => setShow(true);
  const [showLoccs, setShowLoccs] = useState(false);
  const handleCloseLoccs = () => setShowLoccs(false);
  const handleShowLoccs = () => setShowLoccs(true);
  const [buttonText, setButtonText] = useState("Book Now");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [secondaryNumberError, setSecondaryNumberError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [secondaryName, setSecondaryName] = useState("");
  const [secondaryNameError, setSecondaryNameError] = useState("");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const validateSecondaryNumber = () => {
    if (!secondaryNumber.trim()) {
      setSecondaryNumberError("Alternate Number is required.");
      return false;
    } else {
      setSecondaryNumberError("");
      return true;
    }
  };

  const validateSecondaryName = () => {
    if (!secondaryName.trim()) {
      setSecondaryNameError("Secondary Name is required.");
      return false;
    } else {
      setSecondaryNameError("");
      return true;
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/; // Regex to match exactly 10 digits
    return phoneRegex.test(number);
  };

  const handleSecondaryNumberChange = (e) => {
    const number = e.target.value;
    setSecondaryNumber(number);

    if (validatePhoneNumber(number)) {
      setSecondaryNumberError(""); // No error
    } else {
      setSecondaryNumberError("Please enter a valid 10-digit phone number");
    }
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("success-loggedin") === "true";
    setIsLoggedIn(loggedIn);
    const storedPhoneNumber = localStorage.getItem("phone-number");
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  useEffect(() => {
    if (bike && bike.modelId) {
      setModelId(bike.modelId);
    }
  }, [bike]);

  useEffect(() => {
    const fetchBikeDetails = async () => {
      if (modelId) {
        try {
          const response = await axios.post(
            "https://speedrent.in/v2Apis/production/Bookings/getBikeDetails.php",
            new URLSearchParams({
              api_key: "Pavan@2024",
              bikeNumber: modelId,
            })
          );

          setDetailedBikeInfo(response.data.data || null);
        } catch (error) {
          console.error("Error fetching bike details:", error);
        }
      }
    };

    fetchBikeDetails();
  }, [modelId]);

  if (!detailedBikeInfo) {
    return null;
  }

  const renderLocation = () => {
    if (!branch) {
      return <p>Location details not available</p>;
    }

    if (branch === "PATIA") {
      return (
        <span>
          <div className="modal-spec-inside mb-2">
            <i className="fa-solid fa-location-dot me-1 bike-modal-icon"></i>
            Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar, Odisha
            751024.
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59868.451910514756!2d85.7823011298236!3d20.3094440282536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909479cd880d9%3A0xfa93d10a51f53de0!2sRegional%20Transport%20Office%20Bhubaneswar%20II!5e0!3m2!1sen!2sin!4v1724859653743!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style="border:0.5px solid #000000;border-radius: 10px;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>`,
            }}
          />
        </span>
      );
    } else if (branch === "JAGAMARA") {
      return (
        <span>
          <div className="modal-spec-inside mb-2">
            <i className="fa-solid fa-location-dot me-1 bike-modal-icon"></i>
            613/2194, Barabari Rd, Phase-I, Jagamara, Bhubaneswar, Odisha
            751030.
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d912.713407641781!2d85.79470438258764!3d20.262555023985048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjDCsDE1JzQ0LjYiTiA4NcKwNDcnNDQuMiJF!5e0!3m2!1sen!2sin!4v1724860076218!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style="border:0.5px solid #000000;border-radius: 10px;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>`,
            }}
          />
        </span>
      );
    } else {
      return null;
    }
  };

  const handleClick = () => {
    const phoneNumber = "9124046510";
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleZoomToggle = () => {
    setIsZoomed(!isZoomed);
  };

  const handleBookNowClick = async () => {
    if (!isBooking) {
      setButtonText("Confirm Booking");
      setIsBooking(true);
      setShowXMark(true);
    } else {
      const isSecondaryNameValid = validateSecondaryName();
      const isSecondaryNumberValid = validateSecondaryNumber();
      if (
        isSecondaryNameValid &&
        isSecondaryNumberValid &&
        validatePhoneNumber(secondaryNumber)
      ) {
        setSecondaryNumberError("");
        setSecondaryNameError("");
        const bookNowButton = document.querySelector(".book-now-btn-modal");
        const bookingData = new URLSearchParams({
          api_key: "Pavan@2024",
          bikeNumber: bike.modelId,
          number: phoneNumber,
          secondaryName: secondaryName,
          secondaryNumber: secondaryNumber || "",
          pickupDate: pickupDate.split(" ")[0], //split(" ")[0]
          pickupTime: pickupDate.split(" ").slice(1).join(" "), //.split(" ").slice(1).join(" ")
          dropDate: dropDate.split(" ")[0],
          dropTime: dropDate.split(" ").slice(1).join(" "),
          branch: branch,
          bookingAmount: bike.booking_amount,
        });

        try {
          // Send the API request
          const response = await axios.post(
            "https://speedrent.in/v2Apis/production/Bookings/createBooking.php",
            bookingData
          );
          setButtonText("Booking Your Ride!");
          if (response.data.message === "Success") {
            const bookingId = response.data.bookingId;
            if (paymentMethod === "online") {
              // Redirect to payment page
              setTimeout(() => {
                redirectToPayment(bookingId);
              }, 1333);
            } else {
              setTimeout(() => {
                setButtonText("Booked Successfully");
                setTimeout(() => {
                  setShowSuccessPopup(true);
                }, 1200);
                setTimeout(() => {
                  setShowSuccessPopup(false);
                  navigate("/edit-your-speedrent-profile?tab=my-bookings");
                }, 4000);
              }, 2000);
            }
          } else {
            // Handle failure case
            console.error("Booking failed:", response.data.message);
            setButtonText("Booking Failed Please Try Again");
            setTimeout(() => {
              setButtonText("Book Now");
              setIsBooking(false);
              setShowXMark(false);
            }, 2000);
          }
        } catch (error) {
          console.error("API error:", error);
          setButtonText("Network Error!");
          setIsBooking(false);
          setShowXMark(false);
        }

        if (bookNowButton) {
          bookNowButton.classList.add("confirm-booking-btn");
          setTimeout(() => {
            bookNowButton.classList.remove("confirm-booking-btn");
            setButtonText("Book Now");
            setIsBooking(false);
            setShowXMark(false);
          }, 3000);
        }
      } else {
        setSecondaryNumberError("Please enter a valid 10-digit phone number");
        setSecondaryNameError("Please enter a valid name");
      }
    }
  };

  const redirectToPayment = (bookingId) => {
    setIsLoading(true);
    const phoneNumber = localStorage.getItem("phone-number");
    const amount = bike.booking_amount;
    const paymentUrl = `https://speedrent.in/adminapp/cashfree/createOrder.php?phone=${phoneNumber}&amount=${amount}&bookingId=${bookingId}`;

    setTimeout(() => {
      window.location.href = paymentUrl;
    }, 300);
  };

  const handleXMarkClick = () => {
    setIsBooking(false); // Reset booking state
    setShowXMark(false); // Hide X mark button
  };

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={onHide}
      fullscreen="lg-down"
      style={{
        backdropFilter: "blur(3.33px)",
        backgroundColor: "rgba(255, 255, 255, 0.33)",
      }}
    >
      <Modal.Header className="d-flex justify-content-between modal-bike-det-header m-0 px-3 py-2">
        {!showXMark && (
          <button className="close-bike-modal" onClick={onHide}>
            <i className="fa-solid fa-chevron-left fa-lg"></i>
          </button>
        )}
        {showXMark && (
          <button
            className="go-back-to-bike-detail-modal"
            onClick={handleXMarkClick}
          >
            <i className="fa-solid fa-xmark fa-lg"></i>
          </button>
        )}
        <img
          src={mainlogo}
          width="43"
          height="43"
          className="d-inline-block align-top m-0 p-0"
          alt="React Bootstrap logo"
        />
        {/* <Modal.Title>
          <div className="bike-modal-title">
            {detailedBikeInfo?.modelName || "Bike"}
            <span className="free-km-list ms-1">
              {detailedBikeInfo?.kmLimit || "120"}Km Free
            </span>
          </div>
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {!showXMark && (
          <div className="row">
            <div className="col-lg-6 py-2 px-md-3 px-2">
              <BikeImage
                imageUrl={`https://speedrent.in/adminapp/uploads/${bike.modelId}.png`}
                isZoomed={isZoomed}
                onZoomToggle={handleZoomToggle}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div style={{ color: "#1e77ed" }} className="fw-bold">
                  <span>
                    <span className="bike-list-modal-name">
                      {detailedBikeInfo.modelCompany}
                      <span className="free-km-list ms-1">
                        {detailedBikeInfo?.kmLimit || "120"}Km Free
                      </span>
                    </span>
                    <br></br>
                    <span>{detailedBikeInfo?.modelName}</span>
                  </span>
                </div>
                <div className="position-relative d-flex">
                  <span className="text-success fw-bold">
                    ₹&nbsp;{bike.booking_amount}
                  </span>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      id="custom-dropdown-toggle"
                      style={{
                        border: "none",
                        background: "transparent",
                        padding: 0,
                      }}
                    >
                      <i
                        className="fa-solid fa-circle-info ms-1 fa-price-info-modal"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="m-0 p-0 rounded-3 shadow-lg"
                      style={{
                        overflow: "hidden",
                        fontSize: "smaller",
                      }}
                    >
                      <Table striped bordered hover className="m-auto">
                        <thead>
                          <tr>
                            <th>Duration</th>
                            <th>Estimated Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              className="fw-bold"
                              style={{ color: "#ff6600" }}
                            >
                              {duration ? duration : "N/A"}hr
                            </td>
                            <td className="text-success fw-bold">
                              ₹{bike.booking_amount}
                            </td>
                          </tr>
                          <tr>
                            <td>Hourly</td>
                            <td>₹{detailedBikeInfo.hourly || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Daily</td>
                            <td>₹{detailedBikeInfo.daily || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Weekly</td>
                            <td>₹{detailedBikeInfo.weekly || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Monthly</td>
                            <td>₹{detailedBikeInfo.monthly || "N/A"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <hr
              className="d-lg-none mx-auto rounded-pill mt-4 mb-2"
              style={{ width: "95%" }}
            ></hr>
            <div className="col-lg-6 py-2 mx-auto">
              <div className="pb-3">
                <div className="modal-spec">Specifications</div>
                <div className="row modal-spec-inside pt-1">
                  <div className="col-6 d-flex align-items-center">
                    <i className="fa-solid fa-gas-pump me-1 bike-modal-icon fa-lg"></i>
                    {detailedBikeInfo?.fuel || "N/A"}
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <i className="fa-solid fa-gears me-1 bike-modal-icon fa-lg"></i>
                    {detailedBikeInfo?.transmission || "N/A"}
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="modal-spec">Additional Specifications</div>
                <div className="row modal-spec-inside pt-1">
                  <div className="col-6 d-flex align-items-center">
                    <i
                      className="fa-solid fa-circle me-1 bike-modal-icon"
                      style={{ fontSize: "7px" }}
                    ></i>
                    Tool kit
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <i
                      className="fa-solid fa-circle me-1 bike-modal-icon"
                      style={{ fontSize: "7px" }}
                    ></i>
                    Single Helmet
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="modal-spec pb-1">Pickup Location</div>
                {renderLocation()}
              </div>
            </div>
          </div>
        )}
        {showXMark && (
          <div>
            <div className="row px-md-3 px-2">
              <div className="col-lg-6">
                <span className="row d-flex align-items-center">
                  <img
                    src={`https://speedrent.in/adminapp/uploads/${bike.modelId}.png`}
                    alt="Bike"
                    style={{
                      width: 77,
                      height: 77,
                      objectFit: "cover",
                      padding: "2px",
                      border: "0.33px solid #b7b7b7",
                      backgroundColor: "#f3f3f3",
                    }}
                    className="col-4 rounded-3"
                  />

                  <div className="d-flex flex-column justify-content-between col-5 text-start p-0 ps-2 my-auto">
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "medium",
                      }}
                    >
                      <span className="conf-bikecompany">
                        {detailedBikeInfo.modelCompany}
                        <span className="free-km-list ms-1">
                          {detailedBikeInfo?.kmLimit || "120"}Km Free
                        </span>
                      </span>
                    </div>
                    <div
                      className="conf-bike-model"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <strong>{detailedBikeInfo.modelName}</strong>
                    </div>
                  </div>
                  <div className="ms-auto col-3 text-end">
                    <span className="text-success fw-bold">
                      ₹&nbsp;{bike.booking_amount}
                    </span>
                  </div>
                </span>
                <hr />
                <span className="row d-flex align-items-center conf-mod-datettime mt-lg-3 mt-1 rounded-3 border py-2 px-2">
                  <div className="d-flex flex-column justify-content-between col-5 p-0">
                    <div className="text-start startenddatetime-confmod">
                      <span>{pickupDate}</span>
                    </div>
                  </div>
                  <div className="col-2 text-center arrw-confmod p-0">
                    <span>&#8594;</span>
                  </div>
                  <div className="col-5 text-end startenddatetime-confmod p-0">
                    <span>
                      <span>{dropDate}</span>
                    </span>
                  </div>
                </span>
                <span className="row d-flex align-items-center mt-lg-2 mt-1">
                  <div className="d-flex flex-column justify-content-between col-6 text-start">
                    <div>
                      <span className="pik-loc-confmod">Pickup Location</span>
                    </div>
                    <div className="conf-book-branch">
                      <i className="fa-solid fa-location-dot fa-sm me-1 conf-bike-model-location"></i>
                      <span>{branch}</span>
                    </div>
                  </div>
                  <div className="text-end ms-auto col-6">
                    <span className="text-dark fw-bold">
                      <button
                        className="rounded direct-btn-confirm-modal border px-2 py-1"
                        onClick={handleShowLoccs}
                      >
                        <i className="fa-solid fa-diamond-turn-right"></i>
                      </button>
                    </span>
                  </div>
                </span>
                <span className="row mt-lg-2 mt-2">
                  <button
                    className="rounded py-md-2 py-2 border contact-btn-confirm-modal"
                    onClick={handleClick}
                  >
                    Contact Vendor
                  </button>
                </span>
                <div className="row mt-2 codcashcontainer rounded py-2 shadow-sm border">
                  <div className="col-12">
                    <span className="form-label">
                      <b>Select Payment Method</b>
                    </span>
                    <div className="form-check pt-2">
                      <input
                        type="radio"
                        id="cash"
                        name="paymentMethod"
                        value="cash"
                        checked={paymentMethod === "cash"}
                        onChange={handlePaymentMethodChange}
                        className="form-check-input"
                      />
                      <label htmlFor="cash" className="form-check-label">
                        Cash on Delivery
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id="online"
                        name="paymentMethod"
                        value="online"
                        checked={paymentMethod === "online"}
                        onChange={handlePaymentMethodChange}
                        className="form-check-input"
                      />
                      <label htmlFor="online" className="form-check-label">
                        Online Payment (UPI, Credit/Debit Card)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 row p-0 m-0 mt-lg-0 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <hr className="d-lg-none" />
                <div>
                  <span className="text-start fw-bold conf-mod-bildetheader">
                    Personal Details
                  </span>
                  <Form className="mt-2">
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                      <Form.Label>
                        <span className="form-confmod-text">Phone Number</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please Login"
                        disabled
                        className="confmod-primob"
                        value={"+91 " + phoneNumber}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formSecondaryName">
                      <Form.Label>
                        <span className="form-confmod-text">
                          Secondary Name (Required)
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Secondary Name"
                        value={secondaryName}
                        onChange={(e) => setSecondaryName(e.target.value)}
                        isInvalid={!!secondaryNameError} // Check for validation error
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {secondaryNameError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formBasicPassword">
                      <Form.Label>
                        <span className="form-confmod-text">
                          Alternate Number (Required)
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Secondary Phone Number"
                        value={secondaryNumber}
                        onChange={handleSecondaryNumberChange}
                        isInvalid={!!secondaryNumberError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {secondaryNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </div>
                <hr style={{ width: "90%" }} className="mx-auto my-2 p-0" />
                <div>
                  <span className="text-start fw-bold conf-mod-bildetheader">
                    Billing Details
                  </span>
                  <br />
                  <span>
                    <span className="d-flex justify-content-between align-items-center mt-2">
                      <span className="confmod-bildet-lefttext">
                        Time Fare({duration ? duration : "N/A"}hrs)
                      </span>
                      <span className="confmod-bildet-righttext text-success">
                        ₹&nbsp;{bike.booking_amount}
                      </span>
                    </span>
                    <span className="d-flex justify-content-between align-items-center mt-1">
                      <span className="confmod-bildet-lefttext">Free Km</span>
                      <span className="confmod-bildet-righttext">
                        {detailedBikeInfo?.kmLimit || "120"}&nbsp;Km
                      </span>
                    </span>
                    <span className="d-flex justify-content-between align-items-center mt-1">
                      <span className="confmod-bildet-lefttext">
                        Convenience Fee
                      </span>
                      <span className="confmod-bildet-righttext">
                        ₹&nbsp;50.00
                      </span>
                    </span>
                    <span className="d-flex justify-content-between align-items-center mt-1 confmod-bildet-lefttext-discount">
                      <span>Discount</span>
                      <span className="confmoddiskont">-₹&nbsp;50.00</span>
                    </span>
                    <hr />
                    <span className="d-flex justify-content-between align-items-center confmod-bildet-totamt px-2">
                      <span>Total Amount</span>
                      <span>₹&nbsp;{bike.booking_amount}</span>
                    </span>
                    <hr />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-2">
        <div className="d-flex row w-100 m-0 p-0">
          <span className={`p-1 col-6 ${isBooking ? "expanded" : ""}`}>
            {isLoggedIn ? (
              <button
                className={`w-100 rounded border py-2 book-now-btn-modal ${
                  isBooking
                    ? "expand-booking-btn expanded"
                    : "expand-booking-btn"
                }`}
                variant="secondary"
                onClick={handleBookNowClick}
              >
                {buttonText}
              </button>
            ) : (
              <button
                className="w-100 rounded border py-2 book-now-btn-modala"
                onClick={handleShowLoginToBookBike}
              >
                Book Now
              </button>
            )}
          </span>
          {!isBooking && (
            <span className={`p-1 col-6 ${isBooking ? "hidden" : ""}`}>
              <button
                className="w-100 rounded border py-2 contact-modal-btn"
                onClick={handleClick}
              >
                Contact Dealer
              </button>
            </span>
          )}
        </div>
      </Modal.Footer>

      {/* Not logged in, modal direct */}
      <Modal
        show={showLoginToBookBike}
        onHide={handleCloseLoginToBookBike}
        centered
        style={{
          backdropFilter: "blur(3.33px)",
          backgroundColor: "rgba(255, 255, 255, 0.53)",
        }}
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "3.33px solid #ff6600",
          }}
        >
          <Modal.Title>Log In!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="fs-5">
            Please login to start <span>Booking!</span>
          </span>
          <br />
          <span>
            Login{" "}
            <span
              style={{ color: "#ff6600", cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              <u>
                <i>
                  <b>Now</b>
                </i>
              </u>
            </span>
          </span>
        </Modal.Body>
      </Modal>
      <Modal
        show={showLoccs}
        onHide={handleCloseLoccs}
        centered
        style={{
          backdropFilter: "blur(3.33px)",
          backgroundColor: "rgba(255, 255, 255, 0.33)",
        }}
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "3.33px solid #ff6600",
          }}
        >
          <Modal.Title>Pickup Location!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3">
            <div className="modal-spec pb-1">Pickup Location</div>
            {renderLocation()}
          </div>
        </Modal.Body>
      </Modal>
      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="success-popup">
          <i className="fa-solid fa-check success-icon"></i>
        </div>
      )}
      {isLoading && (
        <div className="loading-spinner">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Redirecting to payment page...</p>
        </div>
      )}
    </Modal>
  );
}
