import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  Button,
  Toast,
  Modal,
} from "react-bootstrap";
import logo1 from "../../assets/download.png";
import mainlogo from "../../assets/full-size-logo.png";
import filterimg from "../../assets/filterimg.png";
import axios from "axios";
import "./HeroNavBar.css";
import MobileFilter from "./MobileFilter";

function HeroNavBar({
  scrollToSection,
  showCenterNav = true,
  isBookingPage = false,
  isHeroLogoVisible = true,
  location,
  setLocation,
  onFilterChange,
}) {
  const expand = false; // Change this to 'sm', 'md', 'lg', 'xl', 'xxl' based on your needs.
  const navigate = useNavigate();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sortByPrice, setSortByPrice] = useState(null);
  const [filterByTransmission, setFilterByTransmission] = useState(null);
  const [filterByFuelType, setFilterByFuelType] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [kycDone, setKycDone] = useState(null);
  const [showLocationDropdown, setShowLocationDropdown] = useState(true);

  useEffect(() => {
    const targetDate = new Date('2024-10-23T19:00:00+05:30');

    const checkTime = () => {
      const currentDate = new Date();
      if (currentDate >= targetDate) {
        setShowLocationDropdown(false);
      }
    };

    const interval = setInterval(checkTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleShowModal = () => setShowFilterModal(true);
  const handleCloseModal = () => setShowFilterModal(false);

  const handleSortChange = (sort) => {
    setSortByPrice(sort);
    onFilterChange({
      sort,
      transmission: filterByTransmission,
      fuel: filterByFuelType,
    });
  };

  const handleTransmissionChange = (transmission) => {
    setFilterByTransmission(transmission);
    onFilterChange({ sort: sortByPrice, transmission, fuel: filterByFuelType });
  };

  const handleFuelChange = (fuel) => {
    setFilterByFuelType(fuel);
    onFilterChange({
      sort: sortByPrice,
      transmission: filterByTransmission,
      fuel,
    });
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const loggedInStatus = localStorage.getItem("success-loggedin");
        setIsLoggedIn(loggedInStatus === "true");

        if (loggedInStatus === "true") {
          const phoneNumber = localStorage.getItem("phone-number");

          if (phoneNumber) {
            const response = await axios.post(
              "https://speedrent.in/v2Apis/production/login/getUserInfo.php",
              new URLSearchParams({
                api_key: "Pavan@2024",
                number: phoneNumber,
              })
            );
            const { data } = response.data;
            if (data) {
              setUserName(data.name || data.number); // Set the name or number if name is not available
              setUserNumber(data.number);
              setKycDone(data.kycDone === "true");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setLocation(newLocation);
    // console.log(newLocation);
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("success-loggedin");
    localStorage.removeItem("phone-number");
    setIsLoggedIn(false);
    setShowToast(true);
    setShowLogoutModal(false); // Close the modal
    setTimeout(() => {
      setShowToast(false); // Hide toast after some time
      // navigate("/login");
    }, 3000);
  };

  return (
    <>
      <Navbar
        sticky="top"
        expand={expand}
        id="nav-bar-main-area"
        className="py-0 overflow-hidden border-bottom"
      >
        <Container fluid className="mx-lg-4 mx-md-2">
          <Navbar>
            {isHeroLogoVisible && (
              <Container>
                <Navbar.Brand href="/home">
                  <img
                    src={mainlogo}
                    width="59"
                    height="59"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                </Navbar.Brand>
              </Container>
            )}
            {isBookingPage && (
              <div className="d-flex align-items-center my-2">
                <div>
                  <button
                    className="rounded d-none d-sm-inline border bg-light px-md-3 px-3 py-md-2 py-2 me-2 btnbacksmall"
                    onClick={() => navigate("/home")}
                  >
                    <i
                      className="fa-solid fa-chevron-left"
                      style={{ color: "grey" }}
                    ></i>
                  </button>
                </div>
                {showLocationDropdown && (
                <div>
                  <div className="custom-select-container">
                    <i className="fa-solid fa-location custom-select-location-icon"></i>
                    <select
                      className="custom-select py-md-2 py-2 bg-light"
                      value={location}
                      onChange={handleLocationChange}
                    >
                      <option
                        disabled
                        selected
                        className="custom-select-option"
                        value="setlocation"
                      >
                        Set location
                      </option>
                      <option value="JAGAMARA" className="custom-select-option">
                        Bhubaneshwar - Jagamara
                      </option>
                      <option value="PATIA" className="custom-select-option">
                        Bhubaneshwar - Patia
                      </option>
                    </select>
                    <i className="fa-solid fa-chevron-down custom-select-icon"></i>
                  </div>
                </div>
                )}
                <div className="d-lg-none ms-2">
                  <button
                    className="rounded mobile-filter-btn px-md-3 px-3 py-md-2 py-2 me-2"
                    style={{ backgroundColor: "#01173f", border: "none" }}
                    onClick={handleShowModal}
                  >
                    <img
                      className="img-fluid"
                      src={filterimg}
                      alt="alternative"
                    />
                  </button>
                </div>
              </div>
            )}
          </Navbar>

          {/* Center nav */}
          {showCenterNav && (
            <Nav className="mx-auto d-none d-lg-flex d-md-flex flex-row align-items-center">
              <Nav.Link
                onClick={() => scrollToSection("blogs")}
                className="mx-lg-4 mx-2"
              >
                <span id="lg-md-navbar-items">Blogs</span>
              </Nav.Link>
              <Nav.Link
                onClick={() => scrollToSection("testimonials")}
                className="mx-lg-4 mx-2"
              >
                <span id="lg-md-navbar-items">Testimonials</span>
              </Nav.Link>
              <Nav.Link
                onClick={() => scrollToSection("faqs")}
                className="mx-lg-4 mx-2"
              >
                <span id="lg-md-navbar-items">FAQ's</span>
              </Nav.Link>
              <Nav.Link
                onClick={() => scrollToSection("contactUs")}
                className="mx-lg-4 mx-2"
              >
                <span id="lg-md-navbar-items">Contact Us</span>
              </Nav.Link>
              {isLoggedIn ? (
                <Button
                  id="home-page-login-signup-btn"
                  className="mx-lg-4 mx-2 shadow-sm"
                  onClick={() => navigate("/bike-booking")}
                >
                  Book Now
                </Button>
              ) : (
                <Button
                  id="home-page-login-signup-btn"
                  className="mx-lg-4 mx-2 shadow-sm"
                  onClick={() => navigate("/login")}
                >
                  LOGIN / SIGNUP
                </Button>
              )}
            </Nav>
          )}

          <Navbar.Toggle
            id="navbar-burger"
            aria-controls={`offcanvasNavbar-expand-${expand}`}
          />
          <Navbar.Offcanvas
            className="offcanvas-navbar"
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img
                  src={mainlogo}
                  width="36"
                  height="36"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column justify-content-between">
              <div>
                <Nav className="flex-grow-1 pe-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row">
                      <img
                        src={logo1}
                        width="56"
                        height="56"
                        className="d-inline-block align-top"
                        alt="Profile Image"
                      />
                      <div className="d-flex flex-column ps-2">
                        <p className="mb-0" style={{ fontWeight: "600" }}>
                          Welcome
                        </p>
                        <span
                          className="mb-0"
                          id="profile-name"
                          style={{ cursor: userNumber ? "default" : "pointer" }}
                          {...(!isLoggedIn && {
                            onClick: () => navigate("/login"),
                          })}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                              maxWidth: "150px",
                            }}
                          >
                            {isLoggedIn ? userName : "Please Login.."}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      {isLoggedIn && (
                        <button
                          className="edit-profile-button d-flex align-items-center"
                          onClick={() =>
                            navigate("/edit-your-speedrent-profile?tab=general")
                          }
                        >
                          <i class="fa-solid fa-pencil fa-sm"></i> Edit
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mb-0">
                    <hr />
                  </div>
                  <div id="offcanvas-navitems">
                    <Nav.Link
                      href="/home"
                      className="offset-navbar-links-container rounded ps-2"
                    >
                      <i className="fa-solid fa-home nav-icon-offset-list"></i>
                      &nbsp; Home
                    </Nav.Link>
                    <Nav.Link
                      href="/bike-booking"
                      className="offset-navbar-links-container rounded ps-2"
                    >
                      <i className="fa-solid fa-person-biking nav-icon-offset-list"></i>
                      &nbsp; Book Your Ride
                    </Nav.Link>
                    {isLoggedIn && (
                      <>
                        <Nav.Link
                          href="/edit-your-speedrent-profile?tab=my-bookings"
                          className="offset-navbar-links-container rounded ps-2"
                        >
                          <i className="fa-solid fa-list-ol nav-icon-offset-list"></i>
                          &nbsp; My Bookings
                        </Nav.Link>
                        {/* <Nav.Link
                          href="#action1"
                          className="offset-navbar-links-container rounded ps-2"
                        >
                          <i className="fa-regular fa-heart nav-icon-offset-list"></i>
                          &nbsp; Wishlist
                        </Nav.Link> */}

                        {kycDone ? (
                          <Nav.Link
                            href="/edit-your-speedrent-profile?tab=kyc"
                            className="offset-navbar-links-container rounded ps-2"
                          >
                            <i className="fa-solid fa-shield-halved nav-icon-offset-list"></i>
                            &nbsp; KYC
                            <span
                              id="offcanvas-kyc-custom-notice-true"
                              className="ms-2 px-2 py-1 rounded"
                            >
                              Documents Uploaded!
                            </span>
                          </Nav.Link>
                        ) : (
                          <Nav.Link
                            href="/register-account"
                            className="offset-navbar-links-container rounded ps-2"
                          >
                            <i className="fa-solid fa-shield-halved nav-icon-offset-list"></i>
                            &nbsp; KYC
                            <span
                              id="offcanvas-kyc-custom-notice"
                              className="ms-2 px-2 py-1 rounded"
                            >
                              Complete KYC Now!
                            </span>
                          </Nav.Link>
                        )}
                      </>
                    )}
                    <Nav.Link
                      href="/terms-and-condition"
                      className="offset-navbar-links-container rounded ps-2"
                    >
                      <i className="fa-regular fa-file-lines nav-icon-offset-list"></i>
                      &nbsp; Terms & Condition
                    </Nav.Link>
                    <Nav.Link
                      href="/company-policy"
                      className="offset-navbar-links-container rounded ps-2"
                    >
                      <i className="fa-regular fa-file-lines nav-icon-offset-list"></i>
                      &nbsp; Privacy Policy
                    </Nav.Link>
                    <Nav.Link
                      href="/company-refund-cancelletion"
                      className="offset-navbar-links-container rounded ps-2"
                    >
                      <i className="fa-solid fa-money-bill-transfer nav-icon-offset-list"></i>
                      &nbsp; Refund & Cancellation
                    </Nav.Link>
                  </div>
                </Nav>
              </div>
              <div className="mx-auto mt-auto w-100 mb-2">
                <div className="row">
                  <div className="col-6 px-1">
                    {isLoggedIn ? (
                      <Button
                        id="offcanvas-login-logout-btn"
                        className="w-100 py-2"
                        onClick={handleLogout}
                      >
                        <span className="d-flex justify-content-between align-items-center">
                          <span>LOGOUT</span>
                          <i class="fa-solid fa-right-from-bracket fa-sm"></i>
                        </span>
                      </Button>
                    ) : (
                      <Button
                        id="offcanvas-login-signup-btn"
                        className="w-100 py-2"
                        onClick={() => navigate("/login")}
                      >
                        Login / Signup
                      </Button>
                    )}
                  </div>
                  <div className="col-6 px-1">
                    <Button
                      id="offcanvas-login-book-btn"
                      className="w-100 py-2"
                      onClick={() => navigate("/bike-booking")}
                    >
                      BOOK NOW
                    </Button>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <MobileFilter
        show={showFilterModal}
        handleClose={handleCloseModal}
        onSortChange={handleSortChange}
        onTransmissionChange={handleTransmissionChange}
        onFuelChange={handleFuelChange}
      />

      {/* Toast for logout success */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className="position-fixed top-0 m-3"
      >
        <Toast.Body>Successfully logged out!</Toast.Body>
      </Toast>

      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "3.33px solid #ff6600",
          }}
        >
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <button
            className="rounded border px-3 py-2 text-light "
            onClick={() => setShowLogoutModal(false)}
            style={{ backgroundColor: "#ff6600" }}
          >
            No
          </button>
          <button className="rounded border px-3 py-2" onClick={confirmLogout}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HeroNavBar;
